import React, { Component } from 'react'

class About extends Component {
    render() {
        return (
            <div>
                <p>This project is hosted at Georgia Tech on hosting.gatech.edu </p>
                <p>This website serves as an online catalogue of IceCube neutrino candidate events.</p>
            </div>
        )
    }
}

export default About